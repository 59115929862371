import { MDBCol } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import axiosInstancia, { MAIN_URL } from '../config/axiosInstancia';
import useWindowSize from '../hooks/useWindowSize';

const BannerPublicidad = ({ bannerFor }) => {
    const screenSize = useWindowSize();
    const [banners, setBanners] = useState([]);
    const [banner, setBanner] = useState('');

    useEffect(() => {
        const cargarBanners = async () => {
            const res = await axiosInstancia.get('/banners', { params: { bannFor: bannerFor } });
            console.log(res.data)
            setBanners(res.data.banners);
        }
        cargarBanners();
    }, []);

    useEffect(() => {
        let minWidth = 0;
        if (screenSize.width > 1200) minWidth = 1200
        else if (screenSize.width > 991) minWidth = 991;
        else if (screenSize.width > 767) minWidth = 767;
        else minWidth = 0;

        const tempBanner = banners.find(b => b.BannMinWidth == minWidth);
        if (tempBanner != undefined) setBanner(tempBanner)
    }, [screenSize, banners]);

    return (
        <MDBCol md={12} className='mt-4 card'
            style={{
                borderRadius: 10, height: 220,
                backgroundImage: `url(${MAIN_URL}file/${banner.BannPhoto || ''})`,
                backgroundPosition: 'center',
                cursor: 'pointer'
            }} onClick={v => window.open(banner.BannLink)}>
            {/* x: {screenSize.width} | y: {screenSize.height} */}
        </MDBCol>
    );
}

export default BannerPublicidad;