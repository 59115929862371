import { Input, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useState } from 'react';
import Boton from '../components/Boton';
import axiosInstancia from '../config/axiosInstancia';

const ContraResetAsk = () => {

    const [email, setEmail] = useState('');
    const [ver, setVer] = useState(false);

    const recuperarPass = async (e) => {
        e.preventDefault();
        const res = await axiosInstancia.post('/password/reset', { UserEmail: email });
        setEmail('');
        setVer(true);
    }

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol md={5} className='mt-3 mx-auto'>
                    <MDBCard className='carta'>
                        <MDBCardHeader className='brT-10'>
                            <MDBCardTitle className='barlow fs-18 p-1 m-0'>He olvidado mi contraseña</MDBCardTitle>
                        </MDBCardHeader>
                        <MDBCardBody>
                            <form onSubmit={recuperarPass}>
                                <MDBRow>
                                    <MDBCol md={12}>
                                        <Input
                                            placeholder='Introduzca su correo electrónico'
                                            className='input-nuestro fs-16 p-2'
                                            name='email'
                                            onChange={e => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </MDBCol>
                                    <MDBCol md={12} className='mt-3'>
                                        <Boton
                                            text='Recuperar Contraseña'
                                            className='boton-amarillo float-right'
                                            type='submit'
                                            classText='c-azul'

                                        />
                                    </MDBCol>
                                </MDBRow>
                            </form>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <Modal
                title='Confirmar Acción'
                visible={ver}
                onCancel={() => setVer(false)}
                footer={false}
            ><span className='fs-16'>Si existe una cuenta con este correo electrónico, sera enviado un correo para reiniciar la contraseña.</span></Modal>
        </MDBContainer>
    );
}

export default ContraResetAsk;