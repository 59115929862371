import { MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import ElementoClase from '../components/ElementoClase';
import ElementoFavorito from '../components/ElementoFavorito';
import axiosInstancia from '../config/axiosInstancia';

const Favoritas = () => {

    const [actualizar, setActualizar] = useState(true);
    const [favoritas, setFavoritas] = useState([]);

    const onButtonChange = (i, name, value) => {
        let tempClases = [...favoritas];
        tempClases[i][name] = value;
        setFavoritas([...tempClases]);
    }

    useEffect(() => {
        const cargarFavoritas = async () => {
            if (actualizar) {
                const { data } = await axiosInstancia.get('/favoritas');
                setFavoritas(data.favoritas);
                setActualizar(false)
            }
        }
        cargarFavoritas();
    }, [actualizar])

    return (
        <MDBContainer>
            <h1 className='mt-4 titulo'>Clases Favoritas</h1>
            {favoritas.map((favorita, i) =>
                <ElementoFavorito md={2} clase={favorita} setActualizar={setActualizar}
                    onButtonChange={(name, value) => onButtonChange(i, name, value)} i={i}
                />
            )}
        </MDBContainer>
    );
}

export default Favoritas;