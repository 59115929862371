import { Input, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Boton from '../components/Boton';
import axiosInstancia from '../config/axiosInstancia';

const ContraReset = () => {

    const { token } = useParams();
    const history = useHistory();

    const [datos, setDatos] = useState({});

    const handleChange = e => setDatos({ ...datos, [e.target.name]: e.target.value });

    const restablecerPass = async (e) => {
        e.preventDefault();
        if (datos.NewPass != datos.NewPass2)
            message.warn('Las contraseñas no coinciden.');
        else {
            const res = await axiosInstancia.put('/password/reset', { token, UserPassword: datos.NewPass });
            console.log(res.data);
            message.success('Contraseña Actualizada');
            history.push('/');
        }
    }

    return (
        <MDBContainer>
            <MDBRow>
                <MDBCol md={5} className='mt-3 mx-auto'>
                    <MDBCard className='carta'>
                        <MDBCardHeader className='brT-10'>
                            <MDBCardTitle className='barlow fs-18 p-1 m-0'>Restablecer Contraseña</MDBCardTitle>
                        </MDBCardHeader>
                        <MDBCardBody className='pt-2'>
                            <form onSubmit={restablecerPass}>
                                <MDBRow>
                                    <MDBCol md={12}>
                                        <Input
                                            placeholder='Introduzca la nueva contraseña'
                                            className='input-nuestro fs-16 p-2 mt-2'
                                            name='NewPass'
                                            onChange={handleChange}
                                            value={datos.NewPass}
                                            type='password'
                                        />
                                        <Input
                                            placeholder='Confirme la nueva contraseña'
                                            className='input-nuestro fs-16 p-2 mt-2'
                                            name='NewPass2'
                                            onChange={handleChange}
                                            value={datos.NewPass2}
                                            type='password'
                                        />
                                    </MDBCol>
                                    <MDBCol md={12} className='mt-3'>
                                        <Boton
                                            text='Cambiar Contraseña'
                                            className='boton-amarillo float-right'
                                            type='submit'
                                            classText='c-azul'
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </form>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default ContraReset;