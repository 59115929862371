import axios from 'axios';

// GENERATE_SOURCEMAP=false npx react-scripts build

const debug = false;
export const API_URL = debug ? 'http://192.168.1.39:4000/user' : 'https://api.hrmasteryschool.com/user';
export const MAIN_URL = debug ? 'http://192.168.1.39:4000/' : 'https://api.hrmasteryschool.com/';

const axiosInstancia = axios.create({
    baseURL: API_URL,
    headers: {
        'authorization': localStorage.getItem('accessToken')
    }
});

export default axiosInstancia;