import { message, Progress } from 'antd';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardImage, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Boton from '../components/Boton';
import axiosInstancia, { API_URL, MAIN_URL } from '../config/axiosInstancia';

const Modulos = () => {
    const { CursID } = useParams();

    const [infoCurso, setInfoCurso] = useState({});
    const [modulos, setModulos] = useState([]);

    useEffect(() => {
        const cargarInfo = async () => {
            try {
                const res1 = await axiosInstancia.get('/programas/' + CursID);
                const res2 = await axiosInstancia.get('/programas/' + CursID + '/modulos');
                console.log(res1.data)
                setInfoCurso(res1.data.curso);
                setModulos(res2.data.modulos);
            } catch (err) {
                message.error(err.response.data.message)
            }

        }
        cargarInfo();
    }, []);

    const solicitarCertificado = async () => {
        try {
            const res = await axiosInstancia.post('/programas/' + CursID + '/certificado');
            message.success('Certificado Generado. Lo puede descargar desde Mi Cuenta > Certificados');
        } catch (err) {
            message.warn(err.response.data.message);
        }
    }

    return (
        <>
            <MDBContainer>
                <MDBRow>
                    <MDBCol md={6}>
                        <h2 className='titulo titulo-programa mt-4'>{infoCurso.CursTitle}</h2>
                    </MDBCol>
                    <MDBCol md={6}>
                        {infoCurso.progreso == 100
                            ? <Boton className='mt-4 boton-certificado' classText='fs-16 c-azul text-center' text='Certificado Finalización'
                                onClick={solicitarCertificado}
                            />
                            : <Boton className='mt-4 boton-certificado' classText='fs-16 c-azul text-center' text='Certificado Iniciación'
                                onClick={solicitarCertificado}
                            />
                        }
                    </MDBCol>
                </MDBRow>
                <h2 className='titulo mt-2 mb-3'>Módulos</h2>

                <MDBRow>
                    {
                        modulos.map(mod =>
                            <MDBCol md={6} lg={4} xl={3} className='mb-3'>
                                <Modulo modulo={mod} />
                            </MDBCol>
                        )
                    }

                </MDBRow>
            </MDBContainer>
        </>
    );
}

const Modulo = ({ modulo }) => {
    const history = useHistory();
    return (
        <>
            <MDBCard className='carta'
                onClick={() => history.push('/programas/' + modulo.CursID + '/modulos/' + modulo.ModuID + '/clases')}
            >
                <MDBCardHeader className='header-programa'>
                    <MDBCardImage
                        src={`${MAIN_URL}file/${modulo.ModuMainPhoto}`}
                        className={'imagen-programa ' + (!modulo.disponible ? 'overlay' : '')}
                    />
                </MDBCardHeader>
                <MDBCardBody className='carta-body' >
                    <MDBRow className=' px-2'>
                        <span className='titulo-modulo'>
                            {modulo.ModuOrder}. {modulo.ModuTitle}
                        </span>
                    </MDBRow>
                    <MDBRow className='px-2'>
                        <Progress
                            strokeColor={{
                                '0%': '#52c41a',
                                '100%': '#52c41a',
                            }}
                            showInfo={true}
                            percent={modulo.progreso}
                        // success={{ percent: 0 }}
                        />
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </>
    );
}

export default Modulos;