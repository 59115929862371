import { Col, Input, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useContext, useEffect, useState } from 'react';
import Boton from '../../components/Boton';
import axiosInstancia, { MAIN_URL } from '../../config/axiosInstancia';
import NoImage from '../../assets/noimage.png';
import Loading from '../../assets/loading.gif';
import ModalCropper from '../../components/ModalCropper';
import { resizeAndBase64, toBase64 } from '../../helpers/imageResizer';
import { RootContext } from '../../context/RootContext';

const MiPerfil = () => {

    const { context, setContext } = useContext(RootContext);
    const [imagen, setImagen] = useState(null);
    const [croppedImagen, setCroppedImagen] = useState(null);
    const [mostrarCropper, setMostrarCropper] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [actualizar, setActualizar] = useState(true);
    const [datos, setDatos] = useState({
        UserPassword: '',
        NewPassword: '',
        NewPassword2: ''
    });

    const [verConfirmar, setVerConfirmar] = useState(false);
    useEffect(() => {
        const cargarDatos = async () => {
            if (actualizar) {
                const res = await axiosInstancia.get('/');
                setDatos({ ...datos, ...res.data.user });
                setActualizar(false);
            }
        }
        cargarDatos();
    }, [actualizar]);

    const handleChange = e => setDatos({ ...datos, [e.target.name]: e.target.value });

    const guardarCambios = async () => {
        const res = await axiosInstancia.put('/', {
            UserEmail: datos.UserEmail,
            UserLastName: datos.UserLastName,
            UserFirstName: datos.UserFirstName,
            UserNIF: datos.UserNIF,
            UserFirstDirection: datos.UserFirstDirection,
            UserSecondDirection: datos.UserSecondDirection,
            UserCity: datos.UserCity, UserZIPCode: datos.UserZIPCode,
            UserCountry: datos.UserCountry
        });
        message.success('Datos Actualizados');
    }

    const cambiarContra = async () => {
        if (datos.NewPassword != datos.NewPassword2) message.warn('Las contraseñas deben coincidir');
        else if (datos.NewPassword.length < 6 || datos.NewPassword2.length < 6) message.warn('Las contraseñas deben contener mínimo 6 caractéres');
        else {
            try {
                const res = await axiosInstancia.put('/password', { UserPassword: datos.UserPassword, NewPassword: datos.NewPassword });
                if (res.data.success) setVerConfirmar(true);
            } catch (err) {
                if (!err.response.data.success) message.error(err.response.data.message);
            }
        }
    }

    const cerrarTodas = async () => {
        const res = await axiosInstancia.delete('/sessions');
        window.location.href = '/';
    }

    const dispararCropper = async (e) => {
        console.log('Fresco')
        const file = e.target.files[0];
        const imagenSrc = await resizeAndBase64(file, 'png', false);
        setImagen(imagenSrc);
        setMostrarCropper(true);
    }

    const enviarImagen = async (img) => {
        setMostrarCropper(false);
        setLoadingImage(true);
        const fileInBase64 = await toBase64(img);//await toBase64(img);
        setCroppedImagen(fileInBase64)
        const { data } = await axiosInstancia.put('/profile-photo', { file: fileInBase64 });
        setContext({ ...context, user: { ...context.user, UserPhoto: data.fileName } });
        setActualizar(true)
        setLoadingImage(false);
        setImagen(null);
    }

    return (
        <>
            <MDBCard className='px-3 py-2 carta'>
                <MDBCardBody>
                    <h2 className='titulo'>Datos Personales</h2>
                    <hr />
                    <MDBRow>
                        <MDBCol md={6} className=''>
                            <label for='input-perfil' style={{ display: 'inline' }}>
                                <img className='imagen-perfil mt-3' src={(() => {
                                    if (loadingImage)
                                        return Loading;
                                    else if (datos.UserPhoto)
                                        return `${MAIN_URL}file/${datos.UserPhoto}`
                                    else
                                        return NoImage;
                                })()} />
                            </label>
                            <input type='file' id='input-perfil' onChange={dispararCropper} accept="image/*" />
                        </MDBCol>
                        <MDBCol md={6}>
                            <MDBRow>
                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='Escribe tu nombre'
                                        className='input-nuestro'
                                        name='UserFirstName'
                                        value={datos.UserFirstName}
                                        onChange={handleChange}
                                    />
                                </MDBCol>
                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='Escribe tus apellidos'
                                        className='input-nuestro'
                                        name='UserLastName'
                                        value={datos.UserLastName}
                                        onChange={handleChange}
                                    />
                                </MDBCol>

                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='DNI / NIF'
                                        className='input-nuestro'
                                        name='UserNIF'
                                        value={datos.UserNIF}
                                        onChange={handleChange}
                                    />
                                </MDBCol>

                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='Correo Electrónico'
                                        className='input-nuestro'
                                        name='UserEmail'
                                        value={datos.UserEmail}
                                        onChange={handleChange}
                                        disabled={true}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol>
                            <Boton className='float-right mx-0 mt-3 mb-2 bg-amarillo' text='Guardar Cambios' classText='text-white'
                                onClick={guardarCambios}
                            />
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md={6}>
                            <h2 className='titulo'>Dirección</h2>
                            <hr />
                            <MDBRow>
                                <MDBCol md={12} className='mt-3'>
                                    <Input
                                        placeholder='Primera Linea'
                                        className='input-nuestro'
                                        name='UserFirstDirection'
                                        value={datos.UserFirstDirection}
                                        onChange={handleChange}
                                        type='text'
                                    />
                                </MDBCol>
                                <MDBCol md={12} className='mt-3'>
                                    <Input
                                        placeholder='Segunda Linea'
                                        className='input-nuestro'
                                        name='UserSecondDirection'
                                        value={datos.UserSecondDirection}
                                        onChange={handleChange}
                                        type='text'
                                    />
                                </MDBCol>
                                <MDBCol md={12} className='mt-3'>
                                    <Input
                                        placeholder='Población'
                                        className='input-nuestro'
                                        name='UserCity'
                                        value={datos.UserCity}
                                        onChange={handleChange}
                                        type='text'
                                    />
                                </MDBCol>
                                <Col xs={12} className='pl-3 pr-1 mt-3'>
                                    <Input placeholder='Código Postal'
                                        className='input-nuestro'
                                        name='UserZIPCode'
                                        value={datos.UserZIPCode}
                                        onChange={handleChange}
                                        type='text'
                                    />
                                </Col>
                                <Col xs={12} className='pr-3 pl-1 mt-3'>
                                    <Input placeholder='Pais'
                                        className='input-nuestro'
                                        name='UserCountry'
                                        value={datos.UserCountry}
                                        onChange={handleChange}
                                        type='text'
                                    />
                                </Col>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <Boton className='float-right mx-0 mt-3 bg-amarillo' text='Guardar Cambios' classText='text-white'
                                        onClick={guardarCambios}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol md={6}>
                            <h2 className='titulo'>Cambiar Contraseña</h2>
                            <hr />
                            <MDBRow>
                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='Contraseña Actual'
                                        className='input-nuestro'
                                        name='UserPassword'
                                        value={datos.UserPassword}
                                        onChange={handleChange}
                                        type='password'
                                    />
                                </MDBCol>
                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='Nueva Contraseña'
                                        className='input-nuestro'
                                        name='NewPassword'
                                        value={datos.NewPassword}
                                        onChange={handleChange}
                                        type='password'
                                    />
                                </MDBCol>
                                <MDBCol md={12} className='mt-3'>
                                    <Input placeholder='Repita la nueva contraseña'
                                        className='input-nuestro'
                                        name='NewPassword2'
                                        value={datos.NewPassword2}
                                        onChange={handleChange}
                                        type='password'
                                    />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <Boton className='float-right mx-0 mt-3 bg-amarillo' text='Guardar Cambios' classText='text-white'
                                        onClick={cambiarContra}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>

            <Modal
                title='Cerrar Sesiones'
                visible={verConfirmar}
                onCancel={() => setVerConfirmar(false)}
                cancelText='Cancelar'
                okText='Confirmar'
                onOk={cerrarTodas}
            ><span className='fs-16'>¿Desea cerrar todas las sesiones ?</span></Modal>
            <ModalCropper mostrar={mostrarCropper} setMostrar={setMostrarCropper}
                image={imagen} setImage={setImagen}
                onOk={enviarImagen}
            />
        </>
    );
}

export default MiPerfil;