import { Button } from 'antd';
import { MDBCol } from 'mdbreact';
import React from 'react';
import { MAIN_URL } from '../config/axiosInstancia';

const Opcion = ({ setDiapositiva, opcion }) => {
    if (!opcion.OpciHasImage)
        return (
            <MDBCol md={9} className=''>
                <div
                    onClick={() => setDiapositiva(opcion.OpciRedirect)}
                    className='mb-2 texto-opcion barlow'
                    style={{ width: '100%' }}
                >
                    {opcion.OpciOrder}. {opcion.OpciText}
                </div>
            </MDBCol>
        );
    else
        return (
            <MDBCol md={4} className='mb-3'>
                <div className='my-2 px-auto imagen-opcion barlow'
                    onClick={() => setDiapositiva(opcion.OpciRedirect)}
                >
                    <img src={`${MAIN_URL}file/${opcion.OpciImage}`}
                        style={{
                            height: 89, width: 80, objectFit: 'cover',
                            borderRadius: 5, display: 'block'
                        }} className='mt-1 mb-2 mx-auto'
                    />
                    <h5 className='mt-2 text-center '>{opcion.OpciOrder}. {opcion.OpciText}</h5>
                </div>
            </MDBCol>
        );
}

export default Opcion;