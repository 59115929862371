import { Button, Col, Row } from 'antd';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axiosInstancia from '../../config/axiosInstancia';
import { formatoLegible } from '../../config/constats';
import { mySQLTimeStampToDate } from '../../helpers/dates';
import { splitWith } from '../../helpers/strings';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons'


const MisCertificados = () => {
    const [certificados, setCertificados] = useState([]);
    const [actualizar, setActualizar] = useState(true);
    const [cargado, setCargado] = useState(false);

    const history = useHistory();

    useEffect(() => {
        const cargarDatos = async () => {
            if (actualizar) {
                const res = await axiosInstancia.get('/certificados');
                setCertificados(res.data.certificados.reverse());
                setActualizar(false);
                setCargado(true);
            }
        }

        cargarDatos();
    }, [actualizar]);

    const descargarCertificado = async (cert) => {
        const { data } = await axiosInstancia.get('/certificados/' + cert.CertID + '/pdf');

        let { file } = data;
        file = file.split('base64,')[1];
        const base64URL = file;
        const binary = atob(base64URL.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i += 1) {
            view[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([view], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a"); //Create <a>
        document.body.appendChild(a);
        a.href = url; //Image Base64 Goes here
        a.download = `${cert.CertToken}.pdf`; //File name Here
        a.click(); //Downloaded file
    }

    return cargado && (
        <MDBCard className='carta'>
            <MDBCardHeader className='brT-10'>
                <h1 className='titulo my-1'>Mis Certificados</h1>
            </MDBCardHeader>
            <MDBCardBody className='mt-0 pt-1'>
                {/* <MDBTable responsive>
                    <MDBTableHead>
                        <tr>
                            <th>ID</th>
                            <th>Generado</th>
                            <th>Programa</th>
                            <th>Tipo</th>
                            <th>Acciones</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody> */}
                <MDBRow>
                    {certificados.map((cert, i) =>
                        <MDBCol md={6}>
                            <MDBCard className='mt-3'>
                                <MDBCardBody style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                                    <div style={{ position: 'absolute', top: 0, right: 0, fontSize: 20, padding: 15, cursor: 'pointer' }}
                                        onClick={() => descargarCertificado(cert)}
                                    >
                                        <DownloadOutlined />
                                    </div>

                                    <h3 className='titulo' style={{ display: 'inline' }}>{cert.CursTitle}</h3>
                                    <hr className='mb-2' />
                                    <span className='fs-14'>
                                        <b className="info-bold">Tipo: </b>
                                        {cert.CertIsFinal
                                            ? 'Finalización'
                                            : 'Iniciación'
                                        }
                                    </span>
                                    <hr className="my-2" />
                                    <span className='fs-14'>
                                        <b className="info-bold">Expedición: </b>{mySQLTimeStampToDate(cert.CertTimeStamp, false)}
                                    </span>
                                    <hr className="my-2" />
                                    <span className='fs-14'>
                                        <b className="info-bold">Token: </b>{cert.CertToken}
                                    </span>
                                    <hr className="my-2" />
                                    <span className='fs-14'><b className="info-bold">Acciones: </b></span>
                                    <Button className='boton-siguiente float-right' onClick={() => window.open('/certificado/' + cert.CertToken)}>Compartir</Button>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        // <tr>
                        //     <td>{splitWith(cert.CertToken)}</td>
                        //     <td>{mySQLTimeStampToDate(cert.CertTimestamp)}</td>
                        //     <td>{cert.CursTitle}</td>
                        //     <td>{cert.CertIsFinal ? 'Finalización' : 'Iniciación'}</td>
                        //     <td>
                        //         <Button onClick={() => descargarCertificado(cert)}
                        //         >Descargar</Button>
                        //     </td>
                        // </tr>
                    )}
                </MDBRow>
                {/* </MDBTableBody>
                </MDBTable> */}
            </MDBCardBody>
        </MDBCard>
    );
}

export default MisCertificados;