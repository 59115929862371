import { Collapse, message, Steps, Timeline } from 'antd';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { CheckCircleFilled, HeartOutlined, ShareAltOutlined, LockOutlined, HeartFilled } from '@ant-design/icons';
import axiosInstancia, { API_URL, MAIN_URL } from '../config/axiosInstancia';
import { useHistory, useParams } from 'react-router-dom';
import ElementoClase from '../components/ElementoClase';


const { Panel } = Collapse;

const Clases = ({ clase }) => {
    const { CursID, ModuID, } = useParams();
    const [infoModulo, setInfoModulo] = useState({});
    const [clases, setClases] = useState([]);
    const [cargado, setCargado] = useState(false);
    const [actualizar, setActualizar] = useState(true);

    useEffect(() => {
        const cargarDatos = async () => {
            try {
                if (actualizar) {
                    const res1 = await axiosInstancia.get(`/programas/${CursID}/modulos/${ModuID}`);
                    const res2 = await axiosInstancia.get(`/programas/${CursID}/modulos/${ModuID}/clases`);
                    setInfoModulo(res1.data.modulo);
                    setClases(res2.data.clases);
                    setCargado(true);
                    setActualizar(false);
                }
            } catch (err) {
                console.log(err.response)
                // message.error(err.response.data.message)
            }

        }
        cargarDatos();
    }, [actualizar]);

    const finalizar = async (id) => {
        const res = await axiosInstancia.post(`/programas/${CursID}/modulos/${ModuID}/clases/${id}/finalizar`);
        setActualizar(true);
    }

    const desfinalizar = async (id) => {
        const res = await axiosInstancia.delete(`/programas/${CursID}/modulos/${ModuID}/clases/${id}/finalizar`);
        setActualizar(true);
    }

    const onButtonChange = (i, name, value) => {
        let tempClases = [...clases];
        tempClases[i][name] = value;
        setClases([...tempClases]);
    }

    return cargado && (
        <>
            <MDBContainer>
                <h2 className='titulo titulo-programa mt-5'>{infoModulo.ModuOrder}. {infoModulo.ModuTitle}</h2>
                <h2 className='titulo mt-4 mb-4'>Clases</h2>
                <Timeline className='mt-2 ml-2' >
                    {
                        clases.map((clase, i) =>
                            <Timeline.Item dot={
                                clase.finalizada
                                    ? <CheckCircleFilled style={{ fontSize: 20, color: ' rgb(40, 156, 11)', cursor: 'pointer' }} checked
                                        onClick={() => {
                                            onButtonChange(i, 'finalizada', false);
                                            desfinalizar(clase.ClasID);
                                        }}
                                    />
                                    : clase.disponible
                                        ? <div className='circulo-vacio'
                                        onClick={() => {
                                            onButtonChange(i, 'finalizada', true);
                                            finalizar(clase.ClasID);
                                        }}
                                        />
                                        : <LockOutlined style={{ fontSize: 20, color: ' rgb(40, 156, 11)' }} checked />
                            }>
                                <ElementoClase md={2} clase={clase} setActualizar={setActualizar}
                                    onButtonChange={(name, value) => onButtonChange(i, name, value)}
                                    disponible={clase.disponible} className='px-2'
                                />
                            </Timeline.Item>)
                    }
                </Timeline>
            </MDBContainer>
        </>
    );
}



export default Clases;