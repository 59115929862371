import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardImage, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React from 'react';
import moment from 'moment';
import { formatoLegible, formatoMes } from '../config/constats';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstancia from '../config/axiosInstancia';
import { useState } from 'react';
import { Row, Col, Switch } from 'antd';
import BannerPublicidad from '../components/BannerPublicidad';
import Boton from '../components/Boton';

const Webinars = () => {

    const [pasados, setPasados] = useState(false);
    const [eventos, setEventos] = useState({});
    const [eventosPasados, setEventosPasados] = useState([])

    useEffect(() => {
        const cargarEventos = async () => {
            let res;
            if (pasados) res = await axiosInstancia.get('/eventos', {
                params: {
                    pasados: true
                }
            });
            else res = await axiosInstancia.get('/eventos');
            console.log(res.data)

            pasados ? setEventosPasados(res.data.eventos) : setEventos(res.data.eventos)

        }
        cargarEventos();
    }, [pasados]);

    return (
        <MDBContainer className='mt-3'>
            <MDBRow className='m-0'>
                <Col xs={18} className='titulo fs-24 pl-0 pt-2'>Webinars y Eventos</Col>
                <Col xs={6} className='titulo fs-24 p-0 pl-0 pt-2' >
                    <Boton className='boton-certificado float-right' classText='fs-16 text-center' text={pasados ? 'Apuntarse' : 'Ver Pasados'}
                        onClick={() => setPasados(!pasados)}
                    />
                </Col>
            </MDBRow>
            <BannerPublicidad bannerFor={1} />
            {pasados
                ? <>
                    <MDBCol md={12} className='titulo pl-0 pt-3 fw-500'>Eventos Pasados</MDBCol>
                    <MDBRow>
                        {eventosPasados.map(evento =>
                            <MDBCol md={6} lg={4} className='mt-2'>
                                <ElementoWebinar evento={evento} />
                            </MDBCol>
                        )}
                    </MDBRow>
                </>
                : Object.keys(eventos).length != 0
                    ? Object.keys(eventos).map((key, index) =>
                        <>
                            <MDBCol md={12} className='titulo pl-0 pt-4 fw-500'>{moment(key).format(formatoMes)}</MDBCol>
                            <MDBRow>
                                {eventos[key].map(evento =>
                                    <MDBCol md={6} lg={4} className='mt-2'>
                                        <ElementoWebinar evento={evento} />
                                    </MDBCol>
                                )}
                            </MDBRow>
                        </>
                    )
                    : <h3 className="text-center mt-3 barlow">Ahora mismo no hay eventos abiertos para apuntarte</h3>
            }
        </MDBContainer>
    );
}

const ElementoWebinar = ({ evento }) => {

    const history = useHistory();

    const redirecionar = () => history.push(`/webinars/${evento.EvenID}`);

    return (
        <MDBCard className='carta br-10' style={{ cursor: 'pointer' }}
            onClick={redirecionar}
        >
            <MDBCardBody className='carta-body'>
                <Row>
                    <Col xs={18}>
                        <h2 className='titulo' style={{ fontWeight: 600, fontSize: 22, marginBottom: 0, }}>{evento.EvenTitle}</h2>
                    </Col>
                </Row>
                <span className='titulo' style={{ fontSize: 16, fontWeight: 400 }}>{moment(evento.EvenDate).format(formatoLegible)}</span>
            </MDBCardBody>
            <div style={{
                position: 'absolute',
                backgroundColor: 'var(--rojo)', right: 0,
                borderBottomLeftRadius: 5,
                borderTopRightRadius: 5
            }} className='py-1 px-4'>
                <span className='titulo' style={{
                    fontSize: 16,
                    color: 'white',

                }}>LIVE</span>
            </div>
        </MDBCard>
    );
}

export default Webinars;