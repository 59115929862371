import { Button } from 'antd';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBIcon } from 'mdbreact';
import React from 'react';

const GrupoLinkedin = () => {
  return ( 
      <>
      <MDBCard className="carta">
          <MDBCardHeader className='brT-10'>
            <h1 className='titulo my-1'>Unete al grupo de LinkedIn</h1>
          </MDBCardHeader>
          <MDBCardBody>
            <Button className='boton-siguiente'
              onClick={()=>window.open("https://www.linkedin.com/groups/8973492/")}
            ><MDBIcon fab icon='linkedin' style={{fontSize: 20}}/>&ensp;<span style={{fontSize: 20}}>Unirme</span></Button>
          </MDBCardBody>
        </MDBCard>
      </>
     );
}
 
export default GrupoLinkedin;