import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import axiosInstancia from '../../config/axiosInstancia';
import { mySQLTimeStampToDate } from '../../helpers/dates';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';



const MisLicencias = () => {
    const [licencias, setLicencias] = useState([]);
    const [actualizar, setActualizar] = useState(true);
    const [cargado, setCargado] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const cargarDatos = async () => {
            if (actualizar) {
                const res = await axiosInstancia.get('/licencias');
                setLicencias(res.data.licencias);
                setActualizar(false);
                setCargado(true);
            }
        }

        cargarDatos();
    }, [actualizar]);

    return cargado && (
        <MDBCard className='carta'>
            <MDBCardHeader className='brT-10'>
                <h1 className='titulo my-1'>Mis Licencias</h1>
            </MDBCardHeader>
            <MDBCardBody className='mt-0 pt-1'>
                {/* <MDBTable responsive>
                    <MDBTableHead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha Compra</th>
                            <th>Promoción</th>
                            <th>Inicio - Fin </th>
                            <th>Pago</th>
                            <th>Acciones</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody> */}
                <MDBRow>
                    {licencias.map((lic, i) =>
                        <MDBCol md={6} lg={6}>
                            <TarjetaLicencia licencia={lic} />
                        </MDBCol>
                        // <tr>
                        //     <td>{lic.LiceID}
                        //         {lic.LiceIsEnabled
                        //             ? <span className='text-success'>&ensp;[Activa]</span>
                        //             : <span className='text-danger'>&ensp;[Bloqueada]</span>
                        //         }</td>
                        //     <td>{mySQLTimeStampToDate(lic.LiceTimestamp)}</td>
                        //     <td>(ID: {lic.PromID}) {lic.CursTitle}</td>
                        //     <td>{mySQLTimeStampToDate(lic.PromInicio)} - {mySQLTimeStampToDate(lic.PromFin)}</td>
                        //     <td>{lic.OrdeIsUniquePayment != undefined
                        //         ? lic.OrdeIsUniquePayment
                        //             ? 'Pago Único'
                        //             : 'Suscripción'
                        //         : 'N/A'
                        //     }</td>
                        //     <td>{lic.OrdeIsUniquePayment != undefined
                        //         ? <Button onClick={() => history.push(`/cuenta/licencias/${lic.LiceID}/pagos`)}>Ver Pagos</Button>
                        //         : 'N/A'
                        //     }</td>
                        // </tr>
                    )}
                </MDBRow>
                {/* </MDBTableBody>
                </MDBTable> */}
            </MDBCardBody>
        </MDBCard>
    );
}

const TarjetaLicencia = ({ licencia }) => {

    const history = useHistory();

    return (
        <MDBCard className='mt-3'>
            <MDBCardBody style={{ backgroundColor: 'rgb(240, 240, 240)' }}>
                <MDBRow>
                    <MDBCol>
                        <h3 className='titulo'>{licencia.CursTitle}</h3>
                        <hr className='mb-2'/>
                        <span className='fs-14'>
                            <b className="info-bold">ID: </b>{licencia.LiceID}
                            {licencia.LiceIsEnabled
                                ? <span className='text-success'>&ensp;(Activa)</span>
                                : <span className='text-danger'>&ensp;(Bloqueada)</span>
                            }
                        </span>
                        <hr className="my-2"/>
                        <span className='fs-14'>
                            <b className="info-bold">Promoción: </b>{licencia.CursTitle} | {licencia.PromID}
                        </span>
                        <hr className="my-2"/>
                        <span className='fs-14'>
                            <b className="info-bold">Inicio - Fin: </b>{mySQLTimeStampToDate(licencia.PromInicio, false)} - {mySQLTimeStampToDate(licencia.PromFin, false)}
                        </span>
                        {licencia.OrdeTotal != undefined
                            ? <>
                                <hr className="my-2"/>
                                <span className='fs-14'>
                                    <b className="info-bold">Pago: </b>{licencia.OrdeTotal}€
                                </span>
                            </>
                            : null
                        }
                        {!licencia.OrdeIsUniquePayment && licencia.OrdeTotal != undefined
                            ? <>
                                <hr className="my-2"/>
                                <span className='fs-14'>
                                    <b className="info-bold">Tipo Pago: </b>Suscripción
                            </span>
                            </>
                            : licencia.OrdeTotal != undefined
                                ? <>
                                    <hr className="my-2"/>
                                    <span className='fs-14'>
                                        <b className="info-bold">Tipo Pago: </b>Pago Único
                                    </span>
                                </>
                                : null
                        }
                        {licencia.OrdeTotal != undefined
                            ? <>
                                <hr className="my-2"/>
                                <span className='fs-14'><b className="info-bold">Acciones: </b></span>
                                <Button className='boton-siguiente float-right' onClick={() => history.push(`/cuenta/licencias/${licencia.LiceID}/pagos`)}>Ver Pagos</Button>
                            </>
                            : null
                        }

                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    );
}

export default MisLicencias;