import { MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import Boton from '../components/Boton';
import ElementoFavorito from '../components/ElementoFavorito';
import ElementoNota from '../components/ElementoNota';
import axiosInstancia from '../config/axiosInstancia';
import { htmlPrint } from '../helpers/exportHTML';

const Notas = () => {

    const [actualizar, setActualizar] = useState(true);
    const [notas, setNotas] = useState([]);
    const [loading, setLoading] = useState(false);

    const exportarApuntes = async ()=>{
        setLoading(true);
        const {data} = await axiosInstancia.post('/notas/export');
        htmlPrint(data.file);
        setLoading(false);
    }

    const onButtonChange = (i, name, value) => {
        let tempClases = [...notas];
        tempClases[i][name] = value;
        setNotas([...tempClases]);
    }

    useEffect(() => {
        const cargarFavoritas = async () => {
            if (actualizar) {
                const { data } = await axiosInstancia.get('/notas');
                setNotas(data.notas);
                setActualizar(false)
            }
        }
        cargarFavoritas();
    }, [actualizar])

    return (
        <MDBContainer className='pb-3'>
            <h1 className='my-4 titulo'>Apuntes
                <Boton loading={loading} className='boton-certificado mb-3' classText='fs-16 text-center' 
                    text='Exportar Apuntes' onClick={exportarApuntes}
                />
            </h1>
            {notas.map((nota, i) =>
                <ElementoNota nota={nota} setActualizar={setActualizar}
                    onButtonChange={(name, value) => onButtonChange(i, name, value)}
                />
            )}
        </MDBContainer>
    );
}

export default Notas;