import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HeartOutlined, ShareAltOutlined, HeartFilled } from '@ant-design/icons';
import axiosInstancia, { MAIN_URL } from '../config/axiosInstancia';

const ElementoClase = ({ clase, md, setActualizar, onButtonChange }) => {
    const history = useHistory();
    const { CursID, ModuID } = useParams();

    const agregarFavs = async () => {
        onButtonChange('favorita', true);
        const res = await axiosInstancia.post(`/programas/${CursID}/modulos/${ModuID}/clases/` + clase.ClasID + '/favorita');
        setActualizar(true);
    }

    const eliminarFav = async () => {
        onButtonChange('favorita', false);
        const res = await axiosInstancia.delete(`/programas/${CursID}/modulos/${ModuID}/clases/` + clase.ClasID + '/favorita');
        setActualizar(true);
    }
    const redirigir = () => clase.disponible ? history.push(`/programas/${CursID}/modulos/${ModuID}/clases/${clase.ClasID}?diapositiva=1`) : null;

    return (
        <div className={'px-2 ' + (!clase.disponible ? 'overlay' : '')}>
            <MDBRow className='clase'>
                {clase.favorita
                    ? <HeartFilled className='float-right favorito-sm'
                        onClick={eliminarFav}
                    />
                    : <HeartOutlined className='float-right favorito-sm'
                        onClick={agregarFavs}
                    />
                }
                <div onClick={redirigir} className='col-md-2 col-xs-12 imagen-clase'>
                    <img src={MAIN_URL + 'file/' + clase.ClasMainPhoto}
                    />
                </div>
                <div className='my-auto p-0 col-sm-12 col-md-7' onClick={redirigir}>
                    <h5 className='titulo titulo-clase'>{clase.ClasOrder}. {clase.ClasTitle}</h5>
                </div>
                <div className='my-auto col-md-3'>
                    {clase.favorita
                        ? <HeartFilled className='float-right favorito-md'
                            onClick={eliminarFav}
                        />
                        : <HeartOutlined className='float-right favorito-md'
                            onClick={agregarFavs}
                        />
                    }

                    {/* <span className='float-right' style={{ fontWeight: '600', paddingRight: 25 }}>Compartir</span> */}
                    {/*  */}
                </div>
            </MDBRow>
        </div>
    );
}

export default ElementoClase;