import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { EditTwoTone, DeleteFilled } from '@ant-design/icons';
import axiosInstancia, { MAIN_URL } from '../config/axiosInstancia';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Modal from 'antd/lib/modal/Modal';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ModalConfirmacion from './ModalConfirmacion';

const ElementoNota = ({ nota, setActualizar, onButtonChange }) => {
    const history = useHistory();
    const { CursID, ModuID } = nota;
    const [mostrarEditar, setMostrarEditar] = useState(false);
    const [mostrarEliminar, setMostrarEliminar] = useState(false);
    const [datos, setDatos] = useState({});
    const [textoNota, setTextoNota] = useState(EditorState.createEmpty());

    useEffect(() => {
        setTextoNota(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(nota.NotaContent).contentBlocks)));
        setDatos(nota);
    }, []);

    const cambiarDatos = e => setDatos({ ...datos, [e.target.name]: e.target.value });

    const eliminarNota = async () => {
        onButtonChange('favorita', false);
        const res = await axiosInstancia.delete(`/notas/` + nota.NotaID);
        setActualizar(true);
    }

    const editarNota = async () => {
        await axiosInstancia.put(`/programas/${CursID}/modulos/${ModuID}/clases/${nota.ClasID}/nota/${nota.NotaID}`, {
            NotaTitle: datos.NotaTitle,
            NotaContent: draftToHtml(convertToRaw(textoNota.getCurrentContent()))
        });
        setMostrarEditar(false);
        setActualizar(true);
    }

    const redirigir = () => history.push(`/programas/${CursID}/modulos/${ModuID}/clases/${nota.ClasID}?diapositiva=1`);

    return (
        <>
            <div>
                <MDBRow className='clase m-0 p-0 mb-3'>
                    <MDBIcon icon='trash' className='nota-sm nota-eliminar'
                        onClick={() => setMostrarEliminar(true)}
                    />
                    <MDBIcon icon='pencil-alt' className='nota-sm nota-editar'
                        onClick={() => setMostrarEditar(true)}
                    />
                    <div onClick={redirigir} className='col-md-2 col-xs-12 imagen-clase'>
                        <img src={MAIN_URL + 'file/' + nota.ClasMainPhoto}
                        />
                    </div>
                    <div className='col mt-2 ml-1'>
                        <h4 className='titulo titulo-clase p-0 my-1 fs-20' onClick={redirigir}>{nota.ClasTitle}&ensp;|&ensp;{nota.NotaTitle}</h4>
                        <p style={{ fontWeight: 400, fontSize: 17 }} dangerouslySetInnerHTML={{ __html: nota.NotaContent }} />
                    </div>
                    <MDBCol className='col-md-3 mt-2'>
                        <MDBIcon icon='trash' style={{ fontSize: 20, padding: 15 }} className='float-right nota-md nota-eliminar'
                            onClick={() => setMostrarEliminar(true)}
                        />
                        <MDBIcon icon='pencil-alt' style={{ fontSize: 18, padding: 15, paddingRight: 0, cursor: 'pointer' }} className='float-right nota-md nota-editar'
                            onClick={() => setMostrarEditar(true)}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
            <Modal visible={mostrarEditar} onCancel={() => setMostrarEditar(false)}
                okText='Guardar' title={'Editar Apunte'}
                cancelText='Cancelar' onOk={editarNota}
            >
                <Input value={datos.NotaTitle} name='NotaTitle'
                    onChange={cambiarDatos}
                    placeholder='Título de Nota'
                />
                <Editor
                    wrapperClassName="demo-wrapper mt-2"
                    editorClassName="ant-input"
                    onEditorStateChange={v => setTextoNota(v)}
                    editorState={textoNota}
                />
            </Modal>
            <ModalConfirmacion mostrar={mostrarEliminar} setMostrar={setMostrarEliminar}
                onOk={eliminarNota} text={'¿Está seguro que desea eliminar este apunte?'}
            />
            
        </>
    );
}

export default ElementoNota;