import Modal from 'antd/lib/modal/Modal';
import React from 'react';

const ModalConfirmacion = ({ mostrar, setMostrar, onOk, text }) => {

    const cerrar = ()=>setMostrar(false);

    return (
        <Modal
            title={'Confirmar'}
            visible={mostrar}
            cancelText='Cancelar'
            onCancel={cerrar}
            onOk={()=>{
                onOk();
                cerrar();
            }}
        >{text}</Modal>
    );
}

export default ModalConfirmacion;