import { Progress } from 'antd';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardImage, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import RecruiterLogo from '../assets/recruiter.png';
import { RightOutlined, HeartFilled } from '@ant-design/icons'
import Boton from '../components/Boton';
import axiosInstancia, { MAIN_URL } from '../config/axiosInstancia';
import moment from 'moment';
import BannerPublicidad from '../components/BannerPublicidad';

const Inicio = () => {

    const [licencias, setLicencias] = useState([]);

    useEffect(() => {
        const cargarLicencias = async () => {
            const res = await axiosInstancia.get('/licencias', { params: { notExpired: true } });
            setLicencias(res.data.licencias);
        }
        cargarLicencias();
    }, []);
    return (
        <MDBContainer>
            {/* <h1 className='titulo mb-3 mt-4'>Accesos Rápidos</h1> */}
            <BannerPublicidad bannerFor={0} />
            {/* <h1 className='titulo mt-4'>Accesos Rápidos</h1> */}
            <MDBRow className='mt-3'>
                <AccesoRapido text='Favoritas' icon={<HeartFilled className='mr-2' />} to='/favoritas' />
                <AccesoRapido text='Apuntes' icon={<MDBIcon icon='sticky-note' className='ml-1 mr-2' />} to='/notas' />
            </MDBRow>
            <h1 className='titulo mb-3 mt-4'>Mis Programas</h1>
            <MDBRow >
                {licencias.map(lic =>
                    <MDBCol md={6} lg={6} xl={4} className='mb-3'>
                        <CursoElemento curso={lic} />
                    </MDBCol>
                )}
            </MDBRow>
        </MDBContainer>
    );
}

const AccesoRapido = ({ icon, text, to }) => {
    const history = useHistory();
    return (
        <MDBCol md={4} className='mt-3'>
            <MDBCard className='carta'
                onClick={() => history.push(to)}
                style={{ cursor: 'pointer' }}
            >
                <MDBCardBody className='pr-4 pl-3 py-3'>
                    <h4 className='titulo m-0 my-auto'>
                        {icon}
                        {text}
                        <RightOutlined className='float-right' style={{ color: 'black' }} />
                    </h4>

                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    );
}

const CursoElemento = ({ curso }) => {
    const history = useHistory();
    return (
        <>
            <MDBCard className='carta'>
                <Progress type="circle" percent={curso.progreso} width={35} success={{ percent: -1 }} className='progreso-sm' />
                <MDBCardHeader className='carta-header'>
                    <MDBCardImage
                        src={MAIN_URL + 'file/' + curso.CursLogo}
                        style={{ width: 250 }}
                        className='imagen-curso mx-auto'
                    />
                </MDBCardHeader>
                <MDBCardBody className='carta-body'>
                    <MDBRow>
                        <MDBCol md={9}><h2 className='titulo-carta' style={{ display: 'inline' }}>{curso.CursTitle}</h2></MDBCol>
                        <MDBCol md={3} lassName='text-right'>
                            <Progress type="circle" percent={curso.progreso} width={50} success={{ percent: -1 }} className='progreso-md' />
                        </MDBCol>
                    </MDBRow>
                    <hr className='separacion' />
                    <MDBRow className='mt-3'>
                        <MDBCol >
                            <span className='duracion-curso'>
                                <MDBIcon icon='calendar' />{
                                    moment(curso.PromInicio).unix() < moment().unix()
                                        ? <>&nbsp;Termina {moment(curso.PromFin).fromNow()}</>
                                        : <>&nbsp;Empieza {moment(curso.PromInicio).fromNow()}</>
                                }
                            </span>
                        </MDBCol>
                    </MDBRow>
                    <hr className='separacion' />
                    <MDBRow className='mt-2'>
                        <MDBCol md={curso.siguiente != undefined ? 6 : 12} className='mt-3 px-1'>
                            <Boton className='boton-curso-outline' text='Módulos' classText='c-amarillo'
                                onClick={() => history.push('/programas/' + curso.CursID + '/modulos')}
                            />
                        </MDBCol>
                        {curso.siguiente != undefined
                            ? <MDBCol md={6} className='mt-3 px-1'>
                                <Boton className='boton-curso' text='Próxima Clase' classText='c-azul'
                                    onClick={() => history.push(`/programas/${curso.CursID}/modulos/${curso.siguiente.ModuID}/clases/${curso.siguiente.ClasID}?diapositiva=1`)}
                                />
                            </MDBCol>
                            : null}
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </>
    );
}

export default Inicio;