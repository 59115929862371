import { Button } from 'antd';
import { MDBBtn } from 'mdbreact';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

const Boton = ({ text, onClick, className, classText, type, loading }) => {
    return (
        <Button htmlType={type} className={'boton ' + className} onClick={onClick} >
            <h2 className={'texto-boton ' + classText}>
                {loading && <><LoadingOutlined />&ensp;</>}{text}</h2>
        </Button>
    );
}

export default Boton;