import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { formatoLegible } from '../../config/constats';
import { Button, Col, message, Modal, Row } from 'antd';
import axiosInstancia from '../../config/axiosInstancia';
import { useParams } from 'react-router-dom';
import { Iframe } from '../Clase';


const Inscripcion = () => {

    const params = useParams();

    let fecha;
    const [temporizador, setTemporizador] = useState({
        dias: 31, horas: 5, minutos: 5, segundos: 20
    });
    const [cargado, setCargado] = useState(false);
    const [evento, setEvento] = useState({});
    const [mostrarReserva, setMostrarReserva] = useState(false);

    useEffect(() => {
        const cargarEvento = async () => {
            const { data } = await axiosInstancia.get(`/eventos/${params.EvenID}`);
            setEvento(data.evento);
            fecha = data.evento.EvenDate;
            actualizarTemporizador();
            setCargado(true)
        }
        cargarEvento();
    }, []);

    const actualizarTemporizador = () => {
        let diff = moment.duration(moment(fecha).diff(moment()));
        setTemporizador({ dias: parseInt(diff.asDays()), horas: diff.hours(), minutos: diff.minutes(), segundos: diff.seconds() });
    }

    useEffect(actualizarTemporizador, []);

    useEffect(() => {

        const interval = setInterval(actualizarTemporizador, [999]);

        return () => clearInterval(interval);

    }, []);

    return cargado && (
        <MDBContainer className='mt-3'>
            <MDBRow className=''>
                <MDBCol md={12}>
                    <MDBCard className='carta' style={{ borderRadius: 8 }}>
                        <MDBCardBody className='carta-body'>
                            <h2 className='titulo' style={{ fontWeight: 600, fontSize: 22, marginBottom: 0 }}>{evento.EvenTitle}</h2>
                            <span className='titulo' style={{ fontSize: 16, fontWeight: 400 }}>{moment(evento.EvenDate).format(formatoLegible)}</span>
                        </MDBCardBody>
                        <div style={{
                            position: 'absolute',
                            backgroundColor: 'var(--rojo)', right: 0,
                            borderBottomLeftRadius: 8,
                            borderTopRightRadius: 8
                        }} className='py-1 px-4'>
                            <span className='titulo' style={{
                                fontSize: 16,
                                color: 'white',

                            }}>LIVE</span>
                        </div>
                    </MDBCard>
                </MDBCol>
                {(temporizador.dias < 0 || temporizador.horas < 0 || temporizador.minutos < 0 || temporizador.segundos < 0)
                    ? <>
                        <MDBCol md={12} className='mt-3'>
                            {evento.EvenVimeoURL != undefined 
                                ? <Iframe src={evento.EvenVimeoURL} style={{ borderRadius: 8 }} />
                                : <h3 className="text-center mt-3 barlow">En breve subiremos la grabación del Evento</h3>
                            }
                        </MDBCol>
                    </>
                    : <>
                        <MDBCol md={6} className='mt-3'>
                            <MDBCard style={{ borderRadius: 8 }}>
                                <MDBCardBody className='carta-body'>
                                    <h2 className='titulo fs-20' style={{ fontWeight: 600 }}>Tiempo Restante</h2>
                                    <Row className='py-4'>
                                        <Col xs={6} className='text-center' >
                                            <h2 className='temporizador fs-24 mb-0'>{temporizador.dias}</h2>
                                            <span>dias</span>
                                        </Col>
                                        <Col xs={6} className='text-center' >
                                            <h2 className='temporizador fs-24 mb-0'>{temporizador.horas}</h2>
                                            <span>horas</span>
                                        </Col>
                                        <Col xs={6} className='text-center' >
                                            <h2 className='temporizador fs-24 mb-0 mx-auto'>{temporizador.minutos}</h2>
                                            <span>mins.</span>
                                        </Col>
                                        <Col xs={6} className='text-center' >
                                            <h2 className='temporizador fs-24 mb-0'>{temporizador.segundos}</h2>
                                            <span>segs.</span>
                                        </Col>
                                    </Row>
                                    <MDBRow className='mt-3'>
                                        <MDBCol lg={6} />
                                        <MDBCol lg={6}>
                                            <Button type='primary' className='boton-siguiente barlow titulo py-1' style={{ width: '100%', fontSize: 18 }}
                                                onClick={() => setMostrarReserva(true)} disabled={evento.isIn}
                                            >{evento.isIn ? 'Ya te has registrado' : 'Reservar Plaza'}</Button>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md={6} className='mt-3'>
                            <MDBCard style={{ borderRadius: 8 }}>
                                <MDBCardBody className='carta-body'>
                                    <h2 className='titulo fs-20' style={{ fontWeight: 600 }}>Sobre el Webinar</h2>
                                    <p>{evento.EvenDesc}</p>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </>
                }
            </MDBRow>
            <ModalConfirmarReserva mostrar={mostrarReserva} setMostrar={setMostrarReserva} evento={evento} setEvento={setEvento}
            />
        </MDBContainer>
    );
}

const ModalConfirmarReserva = ({ mostrar, setMostrar, evento, setEvento }) => {

    const [cargando, setCargando] = useState(false);

    const cerrar = () => setMostrar(false);

    const reservar = async () => {
        setCargando(true);
        const { data } = await axiosInstancia.post(`/eventos/${evento.EvenID}/reservar`);

        message.success(data.message);
        setEvento({ ...evento, isIn: true })
        setCargando(false);
        cerrar();
    }

    return (
        <Modal
            visible={mostrar}
            onCancel={cerrar}
            confirmLoading={cargando}
            onOk={reservar}
        >
            <h5>¿Esta seguro que quiere reservar una plaza para {evento.EvenTitle} para el dia {moment(evento.EvenDate).format(formatoLegible)}?</h5>
        </Modal>
    );
}

export default Inscripcion;