import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { HeartOutlined, ShareAltOutlined, HeartFilled } from '@ant-design/icons';
import axiosInstancia, { MAIN_URL } from '../config/axiosInstancia';

const ElementoFavorito = ({ clase, md, setActualizar, onButtonChange, i }) => {
    const history = useHistory();
    const { CursID, ModuID } = clase;


    const eliminarFav = async () => {
        onButtonChange('favorita', false);
        const res = await axiosInstancia.delete(`/favoritas/` + clase.FavoID);
        setActualizar(true);
    }
    const redirigir = () => history.push(`/programas/${CursID}/modulos/${ModuID}/clases/${clase.ClasID}?diapositiva=1`);

    return (
        <div>
            <MDBRow className='clase mt-3 m-0'>
                <HeartFilled className='float-right favorito-sm mr-3'
                    onClick={eliminarFav}
                />
                <div onClick={redirigir} className='col-md-2 col-xs-12 imagen-clase'>
                    <img src={MAIN_URL + 'file/' + clase.ClasMainPhoto}
                    />
                </div>
                <div className='my-auto p-0 col-sm-12 col-md-7' onClick={redirigir}>
                    <h5 className='titulo titulo-clase'>{clase.ClasOrder}. {clase.ClasTitle}</h5>
                </div>
                <div className='my-auto col-md-3'>
                    <HeartFilled className='float-right favorito-md'
                        onClick={eliminarFav}
                    />
                    {/* <span className='float-right' style={{ fontWeight: '600', paddingRight: 25 }}>Compartir</span> */}
                    {/*  */}
                </div>
            </MDBRow>
        </div>
    );
}

export default ElementoFavorito;