import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import axiosInstancia from '../config/axiosInstancia';
import { RootContext } from '../context/RootContext';

const Cerrar = () => {

    const { context } = useContext(RootContext);

    useEffect(() => {
        const cerrarSesion = () => {
            try {
                axiosInstancia.delete('/sessions/' + context.sessionId);
            } catch (err) {

            }
            localStorage.removeItem('accessToken');
            window.location.href = '/';
        }
        cerrarSesion();
    }, []);

    return <h4></h4>;
}

export default Cerrar;