
export const htmlPrint = data => {
    var newWindow = window.open('<h1>Apuntes</h1>', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    //por si el puto iOS me bloquea el pop up de los cojones, me cago en todo 
    if(newWindow ==undefined) {
        newWindow = window;
    }
    newWindow.document.write(data);
    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
}