import { MDBBtn } from 'mdbreact';
import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import BarraNav from './components/BarraNav';
import axiosInstancia from './config/axiosInstancia';
import { routes } from './routes/routes';
import moment from 'moment';
import { QueryParamProvider } from 'use-query-params';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/lib/locale-provider/es_ES';
import 'moment/locale/es';
import { RootContext } from './context/RootContext';
import { Helmet } from 'react-helmet';

moment.locale('es', {
  week: {
    dow: 1,
  },
  weekdaysMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa',]
});

export const formatoHora = 'DD/MM/YYYY HH:mm';

const App = () => {

  const [context, setContext] = useState({
    logged: false //TODO: Cambiar el boole 
  });


  const [cargando, setCargando] = useState(true);

  //Usaremos un memo apra evitar refresh innecesarios
  const rootState = useMemo(() => ({ context, setContext }), [context, setContext]);

  //Comprovar token:

  useEffect(() => {
    const verificarToken = async () => {
      try {
        const res = await axiosInstancia.get('/token');

        if (res.data.success) {
          setContext({ ...context, logged: true, user: res.data.user, sessionId: res.data.sessionId });

        }
        setCargando(false);
      } catch (err) {
        setCargando(false);
      }
    }
    verificarToken();
  }, []);
  

  return !cargando && (
    <RootContext.Provider value={rootState}>
      <ConfigProvider locale={es_ES}>
        <Router>
          <BarraNav />
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              {routes.map(route =>
                route.protected
                  ? <Route exact={route.exact} path={route.path}>
                    {context.logged
                      ? route.component
                      : <Redirect to='/login' />
                    }
                  </Route>
                  : <Route exact={route.exact} path={route.path}>
                    {context.logged && route.onlyLoggedOut
                      ? <Redirect to='/' />
                      : route.component
                    }
                  </Route>
              )}
            </Switch>
          </QueryParamProvider>
        </Router>
      </ConfigProvider>
    </RootContext.Provider>
  );
}

export default App;