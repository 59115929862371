import { Button, Input, message } from 'antd';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import React, { useContext, useEffect, useState } from 'react';
import { formatoHora } from '../../App';
import Boton from '../../components/Boton';
import axiosInstancia from '../../config/axiosInstancia';
import moment from 'moment';
import { RootContext } from '../../context/RootContext';

const MisDispositivos = () => {

    const [sessions, setSessions] = useState([]);
    const { context } = useContext(RootContext);
    const [actualizar, setActualizar] = useState(true);
    const [cargado, setCargado] = useState(false);

    useEffect(() => {
        const cargarDatos = async () => {
            if (actualizar) {
                const res = await axiosInstancia.get('/sessions');
                setSessions(res.data.sessions.reverse());
                setActualizar(false);
                setCargado(true);
            }
        }
        cargarDatos();
    }, [actualizar]);


    const cerrarSesion = async (id) => {
        await axiosInstancia.delete('/sessions/' + id);
        if (id == context.sessionId) window.location.href = '/';
        else {
            message.success('Se ha cerrado la sesión.');
            setActualizar(true);
        }
    }

    return cargado && (
        <>
            <MDBCard className=' carta'>
                <MDBCardHeader className='brT-10'>
                    <h1 className='titulo my-1'>Mis Dispositivos</h1>
                </MDBCardHeader>
                <MDBCardBody>
                    <MDBRow>
                        {sessions.map((session, i) =>
                            <MDBCol md={6}>
                                <MDBCard className='mt-3'>
                                    <MDBCardBody style={{ backgroundColor: 'rgb(240, 240, 240)' }}>

                                        <h3 className='titulo' style={{ display: 'inline' }}>{session.UsSeClient}</h3>
                                        {session.UsSeID == context.sessionId && <><br /> <span className='text-warning '>[Actual]</span></>}
                                        <hr className='mb-2' />
                                        <span className='fs-14'>
                                            <b className="info-bold">Última conexión: </b>
                                            {moment(session.UsSeLastSign).format(formatoHora)}
                                        </span>
                                        <hr className="my-2" />
                                        <span className='fs-14'>
                                            <b className="info-bold">IP: </b>{session.UsSeIP}
                                        </span>
                                        <hr className="my-2" />
                                        <span className='fs-14'><b className="info-bold">Acciones: </b></span>
                                        <Button className='boton-siguiente float-right' onClick={() => cerrarSesion(session.UsSeID)}>
                                            <MDBIcon icon='sign-out-alt' />&ensp;Cerrar Sesión
                                        </Button>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            // <MDBRow>
                            //     <MDBCol md={8} className='mb-3'>
                            //         <span>
                            //             {i + 1}. {moment(session.UsSeLastSign).format(formatoHora)} - {session.UsSeIP || '255.255.255.255'}&ensp;
                            //         {session.UsSeID == context.sessionId && <span className='text-warning '>[Actual]</span>}
                            //             <br />
                            //             {session.UsSeClient}
                            //         </span>
                            //     </MDBCol>
                            //     <MDBCol md={4}>
                            //         <Button className='px-3 float-right m-0'
                            //             type='danger' rounded
                            //             onClick={() => cerrarSesion(session.UsSeID)}
                            //         >
                            //             <MDBIcon icon='sign-out-alt' />&ensp;Cerrar Sesión
                            //         </Button>
                            //     </MDBCol>
                            // </MDBRow>

                        )}
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </>
    );
}

export default MisDispositivos;