import React from 'react';
import Cerrar from '../pages/Cerrar';
import ContraResetAsk from '../pages/ContraResetAsk';
import ContraReset from '../pages/ContraReset';
import Inicio from '../pages/Inicio';
import Login from '../pages/Login';
import MiCuenta from '../pages/MiCuenta';
import MiPerfil from '../pages/MiCuenta/MiPerfil';
import Modulos from '../pages/Modulos';
import { Redirect } from 'react-router-dom';
import Clases from '../pages/Clases';
import Clase from '../pages/Clase';
import Certificado from '../pages/Certificado';
import Favoritas from '../pages/Favoritas';
import { LinkedInPopUp } from 'react-linkedin-login-oauth2';
import Notas from '../pages/Notas';
import PagosLicencias from '../pages/MiCuenta/PagosLicencias';
import Webinars from '../pages/Webinars';
import Inscripcion from '../pages/Webinar/Inscripcion';

export const routes = [
    // {
    //     path: '/',
    //     exact: true,
    //     component: <Redirect to='/programas' />,
    //     protected: true
    // },
    // {   --> Se migra a API para que sea estatico y poder hacer OGM
    //     path: '/certificado',
    //     exact: true,
    //     component: <Certificado />,
    //     protected: false,
    // },
    {
        path: '/webinars/:EvenID',
        exact: false,
        component: <Inscripcion />,
        protected: true,
    },
    {
        path: '/webinars',
        exact: true,
        component: <Webinars />,
        protected: true,
    },
    {
        path: '/programas/:CursID/modulos/:ModuID/clases/:ClasID/',
        exact: false,
        component: <Clase />,
        protected: true
    },
    {
        path: '/programas/:CursID/modulos/:ModuID/clases',
        exact: false,
        component: <Clases />,
        protected: true
    },
    {
        path: '/programas/:CursID/modulos',
        exact: false,
        component: <Modulos />,
        protected: true
    },
    {
        path: '/',
        exact: true,
        component: <Inicio />,
        protected: true
    },
    {
        path: '/login',
        exact: true,
        component: <Login />,
        protected: false,
        onlyLoggedOut: true 
    },
    {
        path: '/cuenta',
        exact: false,
        component: <MiCuenta />,
        protected: true
    },

    {
        path: '/password/reset',
        exact: true,
        component: <ContraResetAsk />,
        protected: false
    },
    {
        path: '/password/:token',
        exact: false,
        component: <ContraReset />,
        protected: false
    },
    {
        path: '/cerrar',
        exact: true,
        component: <Cerrar />,
        protected: true
    },
    {
        path: '/favoritas',
        exact: true,
        component: <Favoritas />,
        protected: true
    },
    {
        path: '/notas',
        exact: true,
        component: <Notas />,
        protected: true
    },
    
];

export const routesCuenta = [
    {
        path: '/cuenta/perfil',
        exact: true,
        component: <MiPerfil />
    },

];
