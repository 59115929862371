import { MDBCard, MDBCardBody, MDBCardHeader, MDBIcon } from 'mdbreact';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Menu = () => {
    return (
        <>
            <MDBCard className='carta p-2'>
                <MDBCardBody>
                    <MenuElemento icon='list-alt' to='/cuenta/licencias' label='Mis Licencias'
                        marginRight={5} />
                    <MenuElemento icon='user-circle' to='/cuenta/perfil' label='Datos Personales'
                        marginRight={5} className='mt-2' />
                    <MenuElemento icon='laptop' to='/cuenta/dispositivos' label='Mis Dispositivos'
                        marginRight={0} className='mt-2' />
                    <MenuElemento icon='certificate' to='/cuenta/certificados' label='Mis Certificados'
                        marginRight={5} />
                    <MenuElemento icon='linkedin' fab={true} to='/cuenta/linkedin' label='LinkedIn'
                        marginRight={9} />
                    <MenuElemento icon='sign-out-alt' to='/cerrar' label='Cerrar Sesión'
                        marginRight={5} className='mt-5' />
                </MDBCardBody>
            </MDBCard>
        </>
    );
}

const MenuElemento = ({ icon, label, to, marginRight, className, fab }) => {

    const loc = useLocation();
    const [actual, setActual] = useState(false);

    useEffect(() => {
        let temp = loc.pathname;
        temp = temp.split('/');
        temp = '/' + temp[1] + '/' + temp[2];
        setActual(temp == to);

    }, [loc]);

    return <>
        <Link to={to} className={`menu-elemento ${actual ? 'menu-elemento-active' : ''} ${className}`}>
            <span>
                <MDBIcon icon={icon} fab={fab} style={{ marginRight: marginRight, fontSize: 20 }} />&ensp;
            {label}
            </span>
        </Link>
    </>;
}

export default Menu;