import React, { useEffect, useState } from 'react';
import axiosInstancia from '../../config/axiosInstancia';
import { useParams } from 'react-router-dom';
import FileDownload from 'js-file-download';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { mySQLTimeStampToDate } from '../../helpers/dates';
import { Button } from 'antd';

const PagosLicencias = () => {
    const [ordenes, setOrdenes] = useState([]);
    const [actualizar, setActualizar] = useState(true);
    const [cargado, setCargado] = useState(false);

    const { LiceID } = useParams();

    useEffect(() => {
        const cargarDatos = async () => {
            if (actualizar) {
                const res = await axiosInstancia.get('/ordenes', { params: { liceId: LiceID } });
                setOrdenes(res.data.ordenes.reverse());
                setActualizar(false);
                setCargado(true);
            }
        }

        cargarDatos();
    }, [actualizar]);

    const descargarFactura = async (orden) => {
        const res = await axiosInstancia.get('/ordenes/' + orden.OrdeID + '/pdf', { responseType: 'blob' });
        FileDownload(res.data, orden.OrdeInvoiceFile);//Downloaded file
    }

    return cargado && (
        <MDBCard className='carta'>
            <MDBCardHeader className='brT-10'>
                <h1 className='titulo my-1'>Pago Licencia {LiceID}</h1>
            </MDBCardHeader>
            <MDBCardBody className='mt-0 pt-1'>
                <MDBTable responsive>
                    <MDBTableHead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha Compra</th>
                            <th>Total</th>
                            <th>Método de Pago</th>
                            <th>Tipo de Pago</th>
                            <th>Factura</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {ordenes.map((orden, i) =>
                            <tr>
                                <td>{orden.OrdeID}</td>
                                <td>{mySQLTimeStampToDate(orden.OrdeTimestamp)}</td>
                                <td>{orden.OrdeTotal.toFixed(2)}€</td>
                                <td>{orden.OrdeMethod}</td>
                                <td>{orden.OrdeIsUniquePayment
                                    ? 'Pago Único'
                                    : 'Suscripción'
                                }</td>
                                <td><Button onClick={() => descargarFactura(orden)}>{orden.OrdeInvoiceFile}</Button></td>
                            </tr>
                        )}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    );
}

export default PagosLicencias;