import { Anchor, Button, Col, Input, message, Progress, Rate, Row, Spin } from 'antd';
import { MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBIcon, MDBRating, MDBRow } from 'mdbreact';
import React, { useEffect, useRef, useState } from 'react';
import axiosInstancia, { MAIN_URL } from '../config/axiosInstancia';
import { useHistory, useParams } from 'react-router-dom';
import Opcion from '../components/Opcion';
import { HeartOutlined, ShareAltOutlined, HeartFilled } from '@ant-design/icons';
import useKeyPress from '../hooks/useKeyPress';
import { useQueryParam, NumberParam } from 'use-query-params';
import Modal from 'antd/lib/modal/Modal';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import loadable from "@loadable/component";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export const Iframe = loadable(() => import('../components/BasicIframe'), {
    fallback: <center>
        <Spin size={'large'} className='pt-3' />
    </center>
});


const Clase = () => {

    const mainDiv = useRef(null);
    const { CursID, ModuID, ClasID } = useParams();
    const history = useHistory();
    const [diapositiva, setDiapositiva] = useQueryParam('diapositiva', NumberParam);
    const [final, setFinal] = useState(false);
    const [diapositivas, setDiapositivas] = useState([]);
    const [favorita, setFavorita] = useState(false);
    const [actualizarFav, setActualizarFav] = useState(true);
    const [mostrarNota, setMostrarNota] = useState(false);
    const [datos, setDatos] = useState({});
    const [actualizar, setActualizar] = useState(true);
    const [nota, setNota] = useState(undefined);
    const [actualizarNota, setActualizarNota] = useState(true);
    const [textoNota, setTextoNota] = useState(EditorState.createEmpty());
    const [loadingImage, setLoadingImage] = useState(true);
    const [entrada, setEntrada] = useState(true);
    const [respuestas, setRespuestas] = useState({});

    const derechaPulsada = useKeyPress('ArrowRight');
    const arribaPulsado = useKeyPress('ArrowUp');
    const enterPulsado = useKeyPress('Enter');
    const izquierdaPulsada = useKeyPress('ArrowLeft');
    const abajoPulsado = useKeyPress('ArrowDown');

    useEffect(() => {
        if (diapositiva == 0 || diapositiva == undefined) setDiapositiva(1);
    }, []);

    useEffect(() => {
        if (!mostrarNota && (derechaPulsada || enterPulsado || arribaPulsado)) siguiente();
        if (!mostrarNota && (izquierdaPulsada || abajoPulsado)) anterior();
    }, [derechaPulsada, izquierdaPulsada, enterPulsado, arribaPulsado, abajoPulsado]);


    useEffect(() => {
        const cargarDiapos = async () => {
            if (actualizar) {
                const { data } = await axiosInstancia.get(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/diapositivas`);
                if (data.success) {
                    if (data.diapositivas.length > 0 && data.diapositivas.length < diapositiva) setDiapositiva(data.diapositivas.length);
                    setDiapositivas(data.diapositivas);
                }

                else {
                    message.warn('Parece ser que la clase esta cerrada.');
                    history.push(`/programas/${CursID}/modulos/${ModuID}/clases/`);
                }
                setActualizar(false)
            }
        }

        cargarDiapos();


    }, [actualizar]);

    useEffect(() => {
        const cargarNota = async () => {
            if (actualizarNota) {
                const { data } = await axiosInstancia.get(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/nota`);
                console.log(data.nota)
                if (data.nota != undefined) {
                    setTextoNota(
                        EditorState.createWithContent(
                            ContentState.createFromBlockArray(htmlToDraft(data.nota.NotaContent).contentBlocks)
                        )
                    );
                    setNota(data.nota);
                    setDatos({ ...data.nota });
                }
                setActualizarNota(false);
            }
        }
        cargarNota();
    }, [actualizarNota]);

    useEffect(() => {
        const cargarFav = async () => {
            if (actualizarFav) {
                const { data } = await axiosInstancia.get(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/favorita`);
                setFavorita(data.favorita);
            }
        }
        cargarFav();
    }, [actualizarFav]);


    const siguiente = () => {
        setLoadingImage(true);
        setEntrada(true);
        if (diapositivas.length == diapositiva) setFinal(true);
        else if (diapositivas[diapositiva - 1].DiapHasCustom) setDiapositiva(parseInt(diapositivas[diapositiva - 1].DiapCustomNext));
        else if (!diapositivas[diapositiva - 1].DiapHasOptions && diapositiva != diapositivas.length) setDiapositiva(diapositiva + 1);
    }

    const anterior = () => {
        setLoadingImage(true);
        setEntrada(false);
        if (diapositiva != 1 && !diapositivas[diapositiva - 1].DiapHasCustom) setDiapositiva(diapositiva - 1);
    }

    const finalizar = async () => {
        const res = await axiosInstancia.post(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/finalizar`, { respuestas });
        setFinal(true);
    }

    const agregarFavs = async () => {
        setFavorita(true);
        await axiosInstancia.post(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/favorita`);
        setActualizarFav(true);
    }

    const eliminarFav = async () => {
        setFavorita(false);
        await axiosInstancia.delete(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/favorita`);
        setActualizarFav(true);
    }

    const compartir = () => {
        const e = document.createElement('textarea');
        e.value = window.location;
        document.body.appendChild(e);
        e.select();
        document.execCommand('copy');
        document.body.removeChild(e);
        message.success('El link ha sido copiado en el portapapeles');
    }

    const cambiarDatos = e => setDatos({ ...datos, [e.target.name]: e.target.value });

    const agregarNota = async () => {
        await axiosInstancia.post(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/nota`, {
            ...datos,
            NotaContent: draftToHtml(convertToRaw(textoNota.getCurrentContent()))
        });
        setMostrarNota(false);
        setActualizarNota(true);
    }

    const editarNota = async () => {
        await axiosInstancia.put(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/nota/${nota.NotaID}`, {
            ...datos,
            NotaContent: draftToHtml(convertToRaw(textoNota.getCurrentContent()))
        });
        setMostrarNota(false);
        setActualizarNota(true);
    }

    const ponerRespuesta = e => {
        let tempDiapositiva = diapositivas[diapositiva - 1];
        let tempRespuestas = { ...respuestas };

        tempRespuestas[tempDiapositiva.DiapID] = e.target.value;
        setRespuestas({ ...tempRespuestas });
    }

    return diapositivas.length != 0 && (
        <>
            <div>
                {!final
                    ? <SwitchTransition mode={entrada ? 'out-in' : 'in-out'}>
                        <CSSTransition
                            key={diapositiva}
                            addEndListener={(node, done) => {
                                node.addEventListener("transitionend", done, false);
                            }} classNames="fade">
                            <div style={{
                                position: 'absolute',
                                top: 0, left: 0, bottom: 0, right: 0,
                                width: '100%',
                                backgroundColor: diapositivas[diapositiva - 1].DiapHasVideo ? 'rgba(0, 0, 0, 0.10)' : "#fbfbfb",
                                zIndex: (100 - diapositiva),
                            }} className='row mx-0 mt-5'
                                ref={mainDiv}
                            >
                                <MDBCol style={{ maxWidth: "800px" }} className='mx-auto mt-5' key={diapositiva} >
                                    <MDBRow >
                                        <MDBCol md={12} className='mt-3'>
                                            <span dangerouslySetInnerHTML={{ __html: diapositivas[diapositiva - 1].DiapText }}
                                                className='texto-clase'
                                            />
                                        </MDBCol>
                                        <div style={{ width: '100%' }}>
                                            {(() => {
                                                if (diapositivas[diapositiva - 1].DiapHasOptions)
                                                    return <MDBCol className='mt-3'>
                                                        <MDBRow style={{ marginBottom: 140 }}>
                                                            {diapositivas[diapositiva - 1].opciones.map(opcion =>
                                                                <Opcion setDiapositiva={setDiapositiva} opcion={opcion} />
                                                            )}
                                                        </MDBRow>
                                                    </MDBCol>
                                                else if (diapositivas[diapositiva - 1].DiapHasDocument)
                                                    return <MDBCol md={12} className='mt-3 mb-2 text-center'>
                                                        <Button className='boton-descarga barlow text-center'
                                                            onClick={() => window.open(`${MAIN_URL}file/${diapositivas[diapositiva - 1].DiapDocument}`)}
                                                        ><MDBIcon icon='save' />&ensp;Descargar Documento</Button>
                                                    </MDBCol>;

                                                else if (diapositivas[diapositiva - 1].DiapHasImage)
                                                    return <MDBCol md={12} className='mt-3 mb-2'>
                                                        {loadingImage
                                                            ? <center>
                                                                <Spin size={'large'} />
                                                            </center>
                                                            : null}
                                                        <img src={`${MAIN_URL}file/${diapositivas[diapositiva - 1].DiapImage}`}
                                                            style={{ maxHeight: '49vh', maxWidth: '100%', marginBottom: 150 }} onLoad={() => setLoadingImage(false)} />
                                                    </MDBCol>;
                                                else if (diapositivas[diapositiva - 1].DiapHasVideo)
                                                    return <MDBCol md={12} className='mb-2'>
                                                        <Iframe src={diapositivas[diapositiva - 1].DiapVideoURL + '?autoplay=1'} />
                                                    </MDBCol>;
                                                else if (diapositivas[diapositiva - 1].DiapHasQuestion)
                                                    return <MDBCol md={8} className='mb-2 mt-3 '>
                                                        <Input className='input-nuestro'
                                                            placeholder={diapositivas[diapositiva - 1].DiapQuestion}
                                                            style={{ width: '100%' }}
                                                            onChange={ponerRespuesta}
                                                            value={respuestas[diapositivas[diapositiva - 1].DiapID]}
                                                        />
                                                    </MDBCol>;
                                            })()}
                                        </div>

                                    </MDBRow>
                                </MDBCol>
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                    : <Valorar />
                }
            </div >

            <Modal visible={mostrarNota} onCancel={() => setMostrarNota(false)}
                okText='Guardar' title={nota != undefined ? 'Editar Apunte' : 'Nuevo Apunte'}
                cancelText='Cancelar' onOk={nota != undefined ? editarNota : agregarNota}
            >
                <Input value={datos.NotaTitle} name='NotaTitle'
                    onChange={cambiarDatos}
                    placeholder='Título de Nota'
                />
                <Editor
                    wrapperClassName="demo-wrapper mt-2"
                    editorClassName="ant-input"
                    onEditorStateChange={v => setTextoNota(v)}
                    editorState={textoNota}
                />
            </Modal>
            {
                !final && (
                    <Row style={{
                        position: 'fixed', bottom: 0,
                        width: '100%', zIndex: 200,
                        backgroundColor: '#fbfbfb',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        boxShadow: '6px -4px 10px 1px rgba(0,0,0,0.3)'
                    }} className='px-4 pb-3 pt-4'>
                        <Col xs={6}>
                            {favorita
                                ? <HeartFilled style={{ fontSize: 28, marginRight: 20 }} className='float-left' onClick={eliminarFav}
                                />
                                : <HeartOutlined style={{ fontSize: 28, marginRight: 20 }} className='float-left' onClick={agregarFavs}
                                />
                            }
                            {nota != undefined
                                ? <MDBIcon icon='sticky-note' className='float-left' style={{ fontSize: 28 }} onClick={() => setMostrarNota(true)} />
                                : <MDBIcon icon='sticky-note' className='float-left' far style={{ fontSize: 28 }} onClick={() => setMostrarNota(true)} />
                            }
                        </Col>
                        <Col xs={18} >
                            <Row>
                                <Col xs={24}>
                                    {!diapositivas[diapositiva - 1].DiapHasOptions
                                        ? diapositiva != diapositivas.length
                                            ? <Button type='primary' style={{ display: 'block' }}
                                                className='float-right ml-3 boton-siguiente barlow'
                                                onClick={siguiente}>Siguiente</Button>
                                            : <Button type='primary' style={{ display: 'block' }}
                                                className='float-right ml-3 boton-siguiente barlow'
                                                onClick={finalizar}>Finalizar</Button>
                                        : null
                                    }
                                    {diapositiva != 1 && !diapositivas[diapositiva - 1].DiapHasCustom
                                        ? <Button onClick={anterior} style={{ display: 'block' }}
                                            className='float-right kill-style'>Anterior</Button>
                                        : null
                                    }
                                </Col>
                                {/* </MDBCol>
                                <MDBCol md={12} className='p-0'> */}
                                <Col xs={24}>
                                    <Progress
                                        className='float-right mt-1'
                                        strokeColor={{
                                            '0%': '#52c41a',
                                            '100%': '#52c41a',
                                        }}
                                        style={{ width: '200px', display: 'block' }}
                                        showInfo={true}
                                        success={{ percent: 0 }}
                                        percent={((diapositiva) * 100 / (diapositivas.length)).toFixed(0)}
                                    // success={{ percent: 0 }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }
        </>
    );
}

const Valorar = () => {
    const { CursID, ModuID, ClasID } = useParams();

    const history = useHistory();
    const [datos, setDatos] = useState({
        ValoIsGood: true,
        ValoText: null
    });

    const lidiarCambio = (n, v) => setDatos({ ...datos, [n]: v });

    const enviarValoracion = async () => {
        await axiosInstancia.post(`/programas/${CursID}/modulos/${ModuID}/clases/${ClasID}/valoracion`, datos);
        message.success('Gracias por tu valoración.');
        volver();
    }

    const volver = () => history.push(`/programas/${CursID}/modulos/${ModuID}/clases/`);

    return (
        <MDBCol md={6} className='mx-auto' style={{ marginTop: '30vh' }}>
            <MDBCard className='carta'>
                <MDBCardBody>
                    <h4>¿Te ha gustado la clase?</h4>
                    <MDBRow>
                        <MDBCol md={12} className='mt-2'>
                            <Button onClick={() => lidiarCambio('ValoIsGood', true)} className={datos.ValoIsGood ? 'boton-siguiente' : 'boton-hover'}
                            ><MDBIcon icon='thumbs-up' />&ensp;Sí, me ha gustado</Button>
                        </MDBCol>
                        <MDBCol md={12} className='mt-2'>
                            <Button onClick={() => lidiarCambio('ValoIsGood', false)} className={datos.ValoIsGood ? 'boton-hover' : 'boton-siguiente'}
                            > <MDBIcon icon='thumbs-down' />&ensp;No, hay cosas a mejorar</Button>
                        </MDBCol>
                        <MDBCol md={12}>
                            {datos.ValoIsGood
                                ? null
                                : <Input
                                    className='mt-3'
                                    placeholder='¿Cómo podemos mejorar?'
                                    value={datos.ValoText}
                                    onChange={e => lidiarCambio('ValoText', e.target.value)}
                                />
                            }
                        </MDBCol>
                        <MDBCol className='mt-3'>
                            <Button className='float-right ml-2 boton-siguiente' onClick={enviarValoracion}>Enviar</Button>
                            {/* <Button className='float-right kill-style' onClick={volver}>Cancelar</Button> */}
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    );
}


export default Clase;