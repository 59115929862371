import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { MDBModal, MDBModalBody } from 'mdbreact';
import React from 'react';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg, toBase64 } from '../helpers/imageResizer';

const ModalCropper = ({ image, onOk, mostrar, setMostrar}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels( croppedAreaPixels)
    }, []);

    const cerrar = () => setMostrar(false);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels);
                
            onOk(croppedImage);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels]);

    return (

        <MDBModal isOpen={mostrar} toggle={cerrar} //style={{borderRadius: 10}}
        >
            <MDBModalBody
                style={{ height: '50vh', padding: 0 }}
            >
                <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    zoomSpeed={0.25}
                    cropShape='round'
                // style={{borderRadius: 10}}
                />
                <Button style={{ width: '100%', position: 'absolute', bottom: 0, height: 'auto', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
                    type='primary' className='fs-20 boton-siguiente barlow titulo py-1' onClick={showCroppedImage}
                >Guardar</Button>
            </MDBModalBody>
        </MDBModal>
    );
}

export default ModalCropper;