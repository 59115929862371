import React, { useContext, useEffect, useState } from 'react';
import Logo from '../assets/logo.png';
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBContainer
} from "mdbreact";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootContext } from '../context/RootContext';
import { MAIN_URL } from '../config/axiosInstancia';

const BarraNav = () => {

    const location = useLocation();
    const { context } = useContext(RootContext);

    const [abierto, setAbierto] = useState(false);

    const toggle = () => setAbierto(!abierto);

    useEffect(() => {
        setAbierto(false);
        if(location.pathname.indexOf('/clases/')!= -1){
            document.getElementById('rootBody').classList.add("hidescroll")
        }else{
            document.getElementById('rootBody').classList.remove("hidescroll")
        }
    }, [location.pathname]);

    return context.logged && (
        <>
            <MDBNavbar color="white" light expand="md" fixed={'top'} style={{ zIndex: 1000 }}>
                <MDBContainer>
                    <MDBNavbarBrand>
                        <Link to='/'>
                            <img src={Logo} style={{ height: 28 }} />
                        </Link>
                    </MDBNavbarBrand>
                    <MDBNavbarToggler onClick={toggle} />
                    <MDBCollapse id="navbarCollapse3" isOpen={abierto} navbar>
                        <MDBNavbarNav right>
                            <MDBNavItem >
                                <MDBNavLink to="/webinars">Webinars</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem >
                                <MDBNavLink to="/">Programas</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="/cuenta/perfil">Mi Cuenta</MDBNavLink>
                            </MDBNavItem>
                            <MDBNavItem>
                                <MDBNavLink to="/cuenta/perfil" className='mt-0'>
                                    {context.user.UserPhoto != undefined
                                        ? <img src={`${MAIN_URL}file/${context.user.UserPhoto}`} className='cuenta-icono-imagen' />
                                        :
                                        <div className='cuenta-icono'>
                                            {context.user.UserFirstName[0]}{context.user.UserLastName[0]}
                                        </div>
                                    }
                                </MDBNavLink>
                            </MDBNavItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
            <div className='margen-barra'></div>
        </>
    );
}

export default BarraNav;