import { Button, Input, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardImage, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Logo from '../assets/logo.png';
import Boton from '../components/Boton';
import axiosInstancia from '../config/axiosInstancia';

const Login = () => {
    const [datos, setDatos] = useState({});
    const [tokenTemp, setTokenTemp] = useState('');
    const [mostrarCerrar, setMostrarCerrar] = useState(false);

    const login = async (e) => {
        if (e != undefined) e.preventDefault();
        try {
            const res = await axiosInstancia.post('/login', datos);
            if (res.data.success) {
                if (res.data.onlySignOut) {
                    setTokenTemp(res.data.token);
                    setMostrarCerrar(true);
                }
                else {
                    localStorage.setItem('accessToken', res.data.token);
                    window.location.href = '/';
                }

            } else message.error('Credenciales Incorrectas');
        } catch (err) {
            message.error(err.response.data.message || 'Error no controlado');
        }
    }

    const handleChange = e => setDatos({ ...datos, [e.target.name]: e.target.value });

    const cerrarSesiones = async () => {
        const res = await axiosInstancia.delete('/sessions', {
            headers: {
                'authorization': tokenTemp
            }
        });
        console.log(res.data);
        setMostrarCerrar(false);
        login();
    }

    return (
        <MDBContainer>
            <MDBRow >
                <MDBCol xl={4} lg={5} md={6} className='mx-auto' style={{ marginTop: '10vh' }}>
                    <MDBCard className='carta mt-3'>
                        <MDBCardHeader className='carta-header bg-white' style={{ borderBottom: '1px solid rgba(0,0,0,.075)' }}>
                            <MDBCardImage
                                src={Logo}
                                className='imagen-login mx-auto'
                            />
                        </MDBCardHeader>
                        <MDBCardBody className='carta-body'>
                            <form onSubmit={login}>
                                <MDBRow>
                                    {/* <MDBCol md={12}>
                                        <h2 className='titulo' style={{ fontSize: 22, fontWeight: 'bold' }}>Iniciar Sesión</h2>
                                    </MDBCol> */}
                                    <MDBCol md={12}>
                                        <Input placeholder='Correo Electrónico'
                                            className='input-nuestro mt-3'
                                            name='UserEmail'
                                            value={datos.UserEmail}
                                            onChange={handleChange}
                                        />
                                    </MDBCol>
                                    <MDBCol md={12}>
                                        <Input placeholder='Contraseña'
                                            type='password'
                                            className='input-nuestro mt-3'
                                            name='UserPassword'
                                            value={datos.UserPassword}
                                            onChange={handleChange}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <Link to='/password/reset' className='he-olvidado'>He olvidado mi contraseña</Link>
                                <MDBRow className='mt-2'>
                                    <MDBCol md={6} className='mt-3'>
                                        {/* <Boton className='boton-curso-outline' text='Módulos' classText='c-azul'
                                        onClick={() => history.push('/programas')}
                                    /> */}
                                    </MDBCol>
                                    <MDBCol md={12} className='mx-right'>
                                        <Boton className='boton-curso' text='Iniciar Sesión' classText='c-azul' type='submit' />
                                    </MDBCol>
                                </MDBRow>
                            </form>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
            <ModalCerrarSesiones
                mostrar={mostrarCerrar}
                setMostrar={setMostrarCerrar}
                tokenTemp={tokenTemp}
                cerrarSesiones={cerrarSesiones}
            />
        </MDBContainer>
    );
}

const ModalCerrarSesiones = ({ mostrar, setMostrar, tokenTemp, cerrarSesiones }) => {

    const cerrar = () => setMostrar(false);



    return (
        <Modal
            title={'Número máximo de sesiones'}
            visible={mostrar}
            onCancel={cerrar}
            footer={
                <Button onClick={cerrarSesiones} type='primary'>Cerrar Sesiones</Button>
                
            }          
        ><h5>Se ha superado el número máximo de sesiones. Cierre alguna sesión en uno de sus dispositivos o cierre todas la sesions.</h5></Modal>
    );
}

export default Login;