import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Menu from './MiCuenta/Menu';
import MiPerfil from './MiCuenta/MiPerfil';
import MisDispositivos from './MiCuenta/MisDispositivos';
import MisLicencias from './MiCuenta/MisLicencias';
import MisCertificados from './MiCuenta/MisCertificados';
import GrupoLinkedin from './MiCuenta/GrupoLinkedin';
import PagosLicencias from './MiCuenta/PagosLicencias';

const MiCuenta = () => {
    return (<>
        <MDBContainer>
            <MDBRow>
                <MDBCol className='mt-3' lg={4}>
                    <Menu />
                </MDBCol>
                <MDBCol className='mt-3' lg={8}>
                    <Switch>
                        <Route exact path='/cuenta/perfil'>
                            <MiPerfil />
                        </Route>
                        <Route path='/cuenta/licencias/:LiceID/pagos'>
                            <PagosLicencias />
                        </Route>
                        <Route path='/cuenta/licencias'>
                            <MisLicencias />
                        </Route>
                        <Route exact path='/cuenta/dispositivos'>
                            <MisDispositivos />
                        </Route>
                        <Route exact path='/cuenta/certificados'>
                            <MisCertificados />
                        </Route>
                        <Route exact path='/cuenta/linkedin'>
                            <GrupoLinkedin/>
                        </Route>
                    </Switch>
                </MDBCol>
            </MDBRow>

        </MDBContainer>
    </>
    );
}

export default MiCuenta;